
import MobileTransactionStats from "@/components/transactions/sections/MobileTransactionStats.vue";
import AddTransactionBtn from "@/components/transactions/ui/AddTransactionBtn.vue";
import { format } from "date-fns";
import TransactionForm from "@/components/transactions/form/TransactionForm.vue";
import NewTransactionForm from "@/components/transactions/form/NewTransactionForm.vue";
import MobileTransactionsList from "@/components/transactions/lists/MobileTransactionsList.vue";
import TransactionStats from "@/components/transactions/sections/TransactionStats.vue";
import SelectProfessional from "@/components/app/forms/SelectProfessional.vue";
import endOfMonth from "date-fns/endOfMonth";
import CustomerSearch from "@/components/customers/ui/CustomerSearch";
import formatISO from "date-fns/formatISO";
import TransactionsExportOptions from "@/components/transactions/ui/TransactionsExportOptions.vue";

export default {
  components: {
    TransactionForm,
    NewTransactionForm,
    MobileTransactionsList,
    TransactionStats,
    AddTransactionBtn,
    MobileTransactionStats,
    SelectProfessional,
    CustomerSearch,
    TransactionsExportOptions,
  },

  data() {
    return {
      searchParams: {
        text: null,
        date_start: null,
        date_end: null,
        professional_id: null,
        status: ["paid", "pending"],
        customer_id: this.getPropCustomerId(),
      },

      stats: {
        sessions: 0,
        shown: 0,
        missed: 0,
      },

      showFilter: false,
      searchMode: false,

      loading: false,

      searchText: null,

      transactions: {
        data: [],
      },

      transactionsSum: [],

      balance: {},
      professional_id: null,
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    // this.setParamsDefault();
    // this.processPermission();
    // this.select();
  },

  methods: {

    getPropCustomerId() {
      if (this.$props?.customer) {
        return this.$props.customer.id
      }
    },

    setParamsDefault() {
      this.searchParams = {
        text: null,
        date_start: format(new Date(), "yyyy-MM-dd"),
        date_end: format(endOfMonth(new Date()), "yyyy-MM-dd"),
        professional_id: null,
      };
    },

    select() {
      this.loading = true;
      this.processPermission();
      this.$http
        .index("transactions/transactions", this.searchParams)
        .then(async (response) => {
          this.transactions = response.transactions;
          this.transactionsSum = response.transactionsSum;
          this.balance = response.balance;
          await this.loadStats();
          this.loading = false;
        })
        .catch((error) => { });
    },
    processPermission() {
      if (this.$acl.forceFilterOwnTransactions()) {
        this.setProfessional(this.user);
      }
    },
    setProfessional(professional) {
      if (professional) {
        this.searchParams.professional = {
          id: professional.id,
          name: professional.name,
        };
        this.searchParams.professional_id = professional.id;
      }

      if (!professional) {
        this.searchParams.professional = null;
        this.searchParams.professional_id = null;
      }
    },

    setCustomer(customer) {
      if (customer) {
        this.searchParams.customer = {
          id: customer.id,
          name: customer.name,
        };
        this.searchParams.customer_id = customer.id;
      }

      if (!customer) {
        this.searchParams.customer = null;
        this.searchParams.customer_id = null;
      }

      this.select();
    },

    handleChangeMonth(range) {
      this.searchParams.date_start = range.start;
      this.searchParams.date_end = range.end;
      this.select();
    },

    openTransactionForm(transaction) {
      var professional_id = transaction.session_group
        ? transaction.session_group.professional_id
        : null;

      if (this.$acl.canUpdateTransaction(professional_id)) {
        this.$refs.TransactionForm.open(transaction.id);
      }
    },

    openNewTransactionForm(type) {
      this.$refs.TransactionForm.new(type);
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleTransactionsExport() {
      this.$loading.start();
      this.$http
        .download("transactions/export", this.searchParams)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Financeiro - ${this.$format.dateBr(formatISO(new Date()))} .xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    async loadStats() {

      var params = {
        customer_id: this.searchParams.customer_id,
        professional_id: this.searchParams.professional_id,
        start: this.searchParams.date_start,
        end: this.searchParams.date_end,
      }

      this.$http.index('sessions/session-stats', params).then(response => {
        this.stats = response.stats
      }).catch(error => {
        console.log(error)
      })
    },
  },
};